<template>
  <div class="dopaper_card">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="答题卡"
               :showBack="true" />
    </div>
    <p class="title">答题情况：</p>
    <div class="card_warp">
      <div :class="getClass(item)"
           v-for="(item,index) in paperList"
           @click="doPaper(index+1)"
           :key="index">
        <span>
          {{index+1}}
        </span>

      </div>
    </div>
    <p class="title">题目图片:<span>
        （显示已提交的图片）</span></p>
    <div class="img_list">

      <div class="img_wrap"
           v-for="(item,index) in images"
           :key="index+item">

        <div style="font-size: 28rem;width:50rem" v-show="!Array.isArray(images)">{{index}}</div>
        <div v-if="Array.isArray(item)"
             class="img_card">
          <div v-for="(img,imgindex) in item"
          style="margin-right: 10rem;"
               :key="imgindex">
            <el-image class="answer"
                      :src="$addAllHeader(img)"
                      :preview-src-list="$addForHeader(item)">
            </el-image>
          </div>
        </div>
        <div v-else>
          <el-image class="answer"
                    :src="$addAllHeader(item)"
                    :preview-src-list="[...item]">
          </el-image>
        </div>
      </div>
    </div>
    <div class="fixed_bottom_btn"
         v-if="type != 'analysis'"
         @click="submitPaper">
      提 交
    </div>
    <CommenToUser ref="CommenToUserRef" />

  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import CommenToUser from '../correct/components/comment.vue'
import { submitUserPaper } from '@/api/my_paper.js'

export default {
  data () {
    return {
      images: [],
      paperList: [],
      info: {},
      type: ''
    }
  },
  components: {
    TopCard, CommenToUser
  },
  mounted () {
    // 看情况处理 先用type做分类，可能不同做卷类型不一样
    this.type = this.$route.query.type
    let info = JSON.parse(window.sessionStorage.getItem('analysis_paper'))
    this.paperList = info.volist
    this.images = info.images
    console.log('thissss', this.images)
    this.info = info

  },
  methods: {
    getClass (item) {
      var str = ''
      if (typeof item.user_score == 'object') {

      } else if (Number(item.user_score) > 0 && (Number(item.question_score) > Number(item.user_score))) {
        str = 'yiban'
      } else if (Number(item.question_score) == Number(item.user_score)) {
        str = 'one_active'
      } else {
        str = 'err'
      }
      return str + ' card'
    },
    doPaper (index) {
      window.sessionStorage.setItem('analysis_index', index - 1)
      this.$router.go(-1)
    },
    async submitPaper () {
      let data = {
        user_paper_id: this.info.user_paper_id,
        user_paper_comment: this.info.user_paper_comment,
        compact: []
      }
      this.info.volist.forEach(item => {
        if (item.auto_judge != 1) {
          data.compact.push({
            user_paper_detail_id: item.user_paper_detail_id,
            comment_to_user: item.comment_to_user,
            user_score: item.user_score,
          })
        }
      });
      this.$refs.CommenToUserRef.form = data
      this.$refs.CommenToUserRef.dialogVisible = true
    },
    async postUserPaper (data) {
      await submitUserPaper(data)
      this.$router.go(-2)
      this.$message.success('提交成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.dopaper_card {
  overflow: hidden;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .title {
    text-indent: 40rem;
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .card_warp {
    width: calc(100vw - 46rem);
    margin: 20rem 6rem 10rem 40rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    max-height: 450rem;
    overflow-y: auto;
    .card {
      width: 120rem;
      height: 120rem;
      border-radius: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f6f6;
      margin-right: 24rem;
      margin-bottom: 30rem;
      font-size: 36rem;
      font-weight: bold !important;
    }
  }
  .img_list {
    margin-top: 30rem;
    margin-left: 40rem;
    display: flex;
    flex-wrap: wrap;

    .img_wrap {
      position: relative;
      margin-right: 24rem;
      display: flex;
      // width: 120rem;
      width: 520rem;
      height: 120rem;
      .img_card {
        display: flex;
      }
      .del {
        position: absolute;
        right: -15rem;
        top: -15rem;
        width: 30rem;
        height: 30rem;
        cursor: pointer;
      }
      .answer {
        border-radius: 10rem;
        width: 120rem;
        height: 120rem;
      }
    }
    .add_img {
      width: 120rem;
      height: 120rem;
      background: #f6f6f6;
      border-radius: 10rem;
      font-size: 20rem;
      font-weight: 500;
      color: #2196f3;
      text-align: center;
      cursor: pointer;
      img {
        width: 40rem;
        height: 34rem;
        margin-top: 26rem;
        margin-bottom: 6rem;
      }
    }
  }
  .fixed_bottom_btn {
    width: calc(100vw - 80rem);
    margin-left: 40rem;
    font-size: 20rem;
    font-weight: bold;
    color: #ffffff;
    height: 60rem;
    background: #2196f3;
    border-radius: 10rem;
    position: fixed;
    bottom: 28rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.one_active {
  background: #ecfcf5 !important;
  color: #0ec3b5 !important;
}

.yiban {
  background: #fcf2ec !important;
  color: #ff916f !important;
}
.err {
  background: #fcecec !important;
  color: #ff6f6f !important;
}
</style>